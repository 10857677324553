/* eslint-disable no-console */
import * as Sentry from '@sentry/browser'
import pkg from '../package.json'
import KnowError from './exceptions/KnownError'

export default function initSentry() {
  console.log('Initializing Sentry')
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserApiErrorsIntegration(),
    ],
    release: import.meta.env.VITE_APP_VERSION ?? pkg.version,
    beforeSend(event, hint) {
      if (hint.originalException instanceof KnowError)
        return null

      return event
    },
  })
}
